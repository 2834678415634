export const user = {
  create: {
    url: "admin/user/create",
    method: "POST",
    data: null,
  },
  update: {
    url: "admin/user/update/",
    method: "PUT",
    id: null,
    data: null,
  },
  get: {
    url: "admin/user/get/",
    method: "GET",
    id: null,
    params: null,
  },
  delete: {
    url: "admin/application/deleteUser/",
    method: "DELETE",
    id: null,
  },
  profile: {
    get: {
      url: "user/profile/details",
      method: "GET",
      data: null,
    },
  },
  resetPassword: {
    get: {
      url: "admin/user/forgotPassword/",
      method: "GET",
      id: null,
    },
  },
  changePassword: {
    url: "admin/user/resetPassword",
    method: "POST",
    date: null,
  },
  resetUserPassword: {
    url: "user/resetPassword",
    method: "POST",
    date: null,
  },
};
